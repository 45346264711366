const goBack = () => {
  const params = new URLSearchParams(window.location.search);
  const ref = params.get('ref');
  if (!ref) {
    window.history.length > 1 ? window.history.back() : window.close();
  } else {
    window.location.href = ref;
  }
};

export { goBack };