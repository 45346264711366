
import { LOGO_SRC } from '../constants/assets';
import { makeUrl } from '../helpers/makeUrl';

const Info = ({ location, allCurrencies }) => {
        const locationWebsite = makeUrl(location.web);
        const name = location.name || '[no name]';
        const addr = !location.full_address ? '[no address]' : `<address>${location.full_address}</address>`;
        const web = locationWebsite ? <a target="_blank" rel="noopenner noreferrer" className="cta-button web-btn" href={locationWebsite}>Visit Website</a> : null;
        
        const buyOnlineBtn = location.web ? <a target="_blank" rel="noopenner noreferrer" className="cta-button buy-online-btn" href={location.web}>Buy Online</a> : null;
        const direction_style = location.online_assisted ? 'directions-button-white' : 'directions-button-green'
        const direction = <a className={`cta-button ${direction_style}`} target="_blank" rel="noopenner noreferrer" href={`https://maps.google.com/maps/dir//${encodeURIComponent(location.full_address)}/@${location.lat},${location.lng},15z`}><span className="button">Directions</span></a>;
        const reportBtn = <a className="cta-button report-button" href={`mailto:support-maps@bitcoin.com?subject=Report about location&body=!${name}, ${location.full_address}\n`}>Report</a>
        const callBtn = location.phone ? <a href={`tel:${location.phone}`} className="cta-button info-phone">Call</a> : null;
        
        return (
            <div className="info-window">
                <div className="fields-wrapper">
                    <img className="logo" src={LOGO_SRC} alt="Bitcoin.com logo" width={170} height={24} />
                    {name ? <h3>{name}</h3> : '[no name]'}
                    {location.description && <div>{location.description}</div>}
                    {addr ? <address>{location.full_address}</address> : '[no address]'}
                    {location.phone && <div className="phone">{location.phone}</div>}
                    {location.online_assisted && <div>
                        <a
                            target="_blank"
                            className="online-assisted-badge"
                            rel="noopenner noreferrer"
                            href={location.web}
                        >
                            Online payments only
                        </a>
                    </div>}
                    {location.instructions && <div className="instructions" dangerouslySetInnerHTML={{ __html: location.instructions}} />}
                </div>
                <div className="cta">
                    {location.online_assisted
                        ? <>
                            {buyOnlineBtn}
                            {callBtn}
                            {direction}https://github.com/bitcoin-portal/bitcoincom-map-frontend/pull/44
                            </>                    
                        : <>
                            {direction}
                            {callBtn}
                            {web}
                        </>
                    }
                </div>
                <div className="fields-wrapper">
                    <div className="currencies-header">
                        Accepted currencies
                    </div>
                    <div className="footer">
                        <div className="currency-list-wrapper">
                            {location.currencies.map(code => {
                                const c = allCurrencies[code];
                                return (
                                    <div key={code} className="currency">
                                        <img
                                            src={c.icon_url}
                                            width="24"
                                            height="24" 
                                            alt={`${c.name} is supported`} />
                                    </div>
                                );
                            })}
                        </div>
                        {reportBtn}
                </div>
            </div>
            </div>
        );
};

export default Info;