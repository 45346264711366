const isIframe = () => window.self !== window.top;
const isUnsupported = () => !window.document.fullscreenEnabled;
const isFullScreen = (element) => window.document.fullscreenElement === element;

const createButtonElement = () => {
    const button = window.document.createElement('button');

    button.className = 'btn-fullscreen';
    button.type = 'button';
    button.title = 'Toggle full screen';

    return button;
};

const openFullScreen = (element) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
};

const closeFullScreen = () => {
    if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
    } else if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen();
    } else if (window.document.msExitFullscreen) {
        window.document.msExitFullscreen();
    }
};

const toggleFullScreen = (event) => {
    const element = window.document.documentElement;
    const button = event.target;

    if (isFullScreen(element)) {
        closeFullScreen();
        button.classList.remove('fs-close');
    } else {
        openFullScreen(element);
        button.classList.add('fs-close');
    }
};

const addFullScreenButton = (map, fullScreenElement) => {
    const googleMaps = window.google.maps;
    const element = fullScreenElement || window.document.documentElement;

    if (!map || !googleMaps || isUnsupported() || isIframe()) return;

    const button = createButtonElement();
    if (isFullScreen(element)) {
        button.classList.add('fs-close');
    }
    button.onclick = toggleFullScreen;

    const fullScreenControlDiv = window.document.createElement('div');
    fullScreenControlDiv.appendChild(button);
    map.controls[googleMaps.ControlPosition.RIGHT_BOTTOM].push(
        fullScreenControlDiv
    );
};

export { addFullScreenButton };
