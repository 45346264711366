export const REGIONAL_VIEWS = {
    mobile: {
        WORLD: {
            lat: 0,
            lng: 22.3183801,
            zoom: 1,
        },
        US: {
            lat: 36.9335566,
            lng: -99.4193908,
            zoom: 3.5,
        },
        CA: {
            lat: 59.5969281,
            lng: -101.9699931,
            zoom: 3,
        },
        GB: {
            lat: 54.2842822,
            lng: -4.0698462,
            zoom: 5.5,
        },
        VE: {
            lat: 7.1177106,
            lng: -66.5204924,
            zoom: 5,
        },
    },
    desktop: {
        WORLD: {
            lat: 32.3885158,
            lng: 5.2032709,
            zoom: 3,
        },
        US: {
            lat: 37.9335566,
            lng: -99.4193908,
            zoom: 5.3,
        },
        CA: {
            lat: 59.6324319,
            lng: -105.278973,
            zoom: 4.39,
        },
        GB: {
            lat: 54.2210477,
            lng: -4.6383257,
            zoom: 6,
        },
        VE: {
            lat: 6.5018598,
            lng: -64.8910742,
            zoom: 6.8,
        },
    }
};

// Custom views for a country code ('WORLD' is the default fallback)
export const COUNTRY_CODE_MAP = {};
