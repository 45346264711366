import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types';
import SearchFormV2 from './SearchFormV2'
import SubmitForm from "./SubmitForm";
import Info from './Info';
import Filter from './Filter';
import Panel from './Panel';
import Ad from './Ad';
import Utils from "./Utils";
import { UIContext } from '../context/ui';
import { addFullScreenButton } from '../helpers/addFullScreenButton';
import { getInitialView } from '../helpers/getInitialView';
import './Map.scss';
import './Ad.scss';


class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapInitialised: false,
      lat: null,
      lng: null,
      n_lat: null,
      w_lng: null,
      s_lat: null,
      e_lng: null,
      zoom: null,
      hideSearch: false,
      submitFormOpened: false,
      panelContentData: null,
      currencies: {},
      categoriesActive: [],
      categoriesPassive: [],
      selectedCategory: null,
      loading: false,
    };
    this.googleMapRef = createRef();
    this.googleMap = null;
    this.oms = null;
    this.markerPhysicalImage = null;
    this.markerOnlineImage = null;
    this.markerSelectedImage = null;
    this.clusterImage = null;
    this.infoWindow = null;
    this.markers = {};
    this.newMarkerIds = [];
    this.allCategories = [];
    this.categoriesWereForCoordinates = null;
  }

  componentDidMount() {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener('load', async () => {
      this.googleMap = await this.createGoogleMap();
      this.goToUserLocation();
      this.geocoder = new window.google.maps.Geocoder();
      this.initOMSLib();
    })
  }

  initOMSLib = () => {
    const omsScript = document.createElement('script');
    omsScript.src = `/vendor/oms.min.js`;
    window.document.body.appendChild(omsScript);

    omsScript.addEventListener('load', () => {
      this.oms = new window.OverlappingMarkerSpiderfier(this.googleMap, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
        circleFootSeparation: 40,

        spiralFootSeparation: 35,
        spiralLengthStart: 19,
        spiralLengthFactor: 5,

        circleSpiralSwitchover: 12,
        keepSpiderfied: true
      });

      this.setState({mapInitialised: true});
    })
  };

  createGoogleMap = async () => {
    const { lat, lng, zoom } = await getInitialView();
    
    const map = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: this.props.initialZoom || zoom,
      center: {
        lat: this.props.initialLat || lat,
        lng: this.props.initialLng || lng,
      },
      disableDefaultUI: true,
      gestureHandling: 'greedy',
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM
      },
      scrollwheel: !this.props.isPreview,
      styles: [
        {
          "featureType": "poi",
          "stylers": [
            { "visibility": "off" }
          ]
        }
      ]
    });

    if (this.context.hasFullScreenButton) {
      addFullScreenButton(map);
    }

    window.google.maps.event.addListener(map, 'dragend', this.onMapDragEnd);
    window.google.maps.event.addListener(map, 'zoom_changed', this.onMapDragEnd);
    window.google.maps.event.addListener(map, 'resize', this.onMapDragEnd);
    window.google.maps.event.addListener(map, 'click', this.onMapClick);
    window.google.maps.event.addListenerOnce(map, 'tilesloaded', this.onMapDragEnd);

    this.markerPhysicalImage = {
      url: '/img/marker-physical.png',
      size: new window.google.maps.Size(34, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(18, 50),
      scaledSize: new window.google.maps.Size(34, 50)
    };

    this.markerOnlineImage = {
      url: '/img/marker-online.png',
      size: new window.google.maps.Size(34, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(18, 50),
      scaledSize: new window.google.maps.Size(34, 50)
    };

    this.markerSelectedImage = {
      url: '/img/marker-selected.png',
      size: new window.google.maps.Size(34, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(18, 50),
      scaledSize: new window.google.maps.Size(34, 50)
    };

    this.clusterImage = {
      url: '/img/cluster.png',
      size: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(25, 50),
      scaledSize: new window.google.maps.Size(50, 50)
    };

    const contentString = '';
    this.infoWindow = new window.google.maps.InfoWindow({
      content: contentString
    });
    return map;
  };

  goToUserLocation = () => {
    if (!navigator.geolocation) {
      return
    }
    const options = { timeout: 10 * 1000, enableHighAccuracy: false };
    const onSuccess = (position) => {
      const loc = {lat: position.coords.latitude, lng: position.coords.longitude };
      this.googleMap.setCenter(loc);
      this.googleMap.setZoom(10);
      this.onMapDragEnd();
    };
    const onFail = e => console.log('e', e);
    navigator.geolocation.getCurrentPosition(onSuccess, onFail, options);
  };

  search = (query, location) => {
    this.filterClose();
    if (location !== null) {
      this.googleMap.setCenter(location);
      this.googleMap.setZoom(19);
      this.onMapDragEnd();
    } else {
      this.geocoder.geocode(
        {address: query, bounds: this.googleMap.getBounds()},
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const location = results[0].geometry.location;
            this.googleMap.setCenter(location);
            this.googleMap.setZoom(19);
            this.onMapDragEnd();
          } else {
            alert("Sorry, we could not find any information about location you've entered!");
          }
        }
      );
    }
  };

  onMapDragEnd = () => {
    const center = this.googleMap.getCenter();
    const bounds = this.googleMap.getBounds();
    const zoom = this.googleMap.getZoom();
    if (bounds === undefined) { console.log('Map is not ready'); return }
    const n_lat = Math.round(bounds.getNorthEast().lat() * 1000000) / 1000000;
    const s_lat = Math.round(bounds.getSouthWest().lat() * 1000000) / 1000000;
    const w_lng = Math.round(bounds.getSouthWest().lng() * 1000000) / 1000000;
    const e_lng = Math.round(bounds.getNorthEast().lng() * 1000000) / 1000000;
    const c_lat = Math.round(center.lat() * 1000000) / 1000000;
    const c_lng = Math.round(center.lng() * 1000000) / 1000000;
    this.setState({ n_lat: n_lat, w_lng: w_lng, s_lat: s_lat, e_lng: e_lng, lat: center.lat(), lng: center.lng(), zoom: zoom });
    let url = `${process.env.REACT_APP_API_ENDPOINT}/locations?n_lat=${n_lat}&w_lng=${w_lng}&s_lat=${s_lat}&e_lng=${e_lng}&zoom=${zoom}`;
    const currencyCount = Object.keys(this.state.currencies).length;
    if (currencyCount === 0) { url += '&need_dicts=1' }
    const distThreshold = 1;
    const categoryDist = (this.categoriesWereForCoordinates &&
      Math.abs(this.categoriesWereForCoordinates.lat - c_lat) + Math.abs(this.categoriesWereForCoordinates.lng - c_lng) +
      Math.abs(this.categoriesWereForCoordinates.zoom - zoom))
      || distThreshold
    if (categoryDist >= 0.5) {
      url += `&c_lat=${c_lat}&c_lng=${c_lng}`
      this.categoriesWereForCoordinates = { lat: c_lat, lng: c_lng, zoom: zoom }
    }
    if (this.state.selectedCategory) { url += `&category=${encodeURIComponent(this.state.selectedCategory)}` }
    const selectedCurrencies = Object.keys(this.state.currencies).filter(c => this.state.currencies[c].checked )
    if (currencyCount > 0 && selectedCurrencies.length === 0) {
      this.createMarkers({clusters: [], data: []})
      this.stopLoading()
      return
    }
    if (selectedCurrencies.length < currencyCount) { url += `&currencies=${selectedCurrencies.join(',')}` }

    const headers = {'x-api-key': process.env.REACT_APP_BACKEND_API_KEY};
    fetch(url, {headers: headers})
      .then(response => response.json())
      .then(q => { this.processCategories(q) ; this.processCurrencies(q) ; this.stopLoading() ; this.createMarkers(q) })
      .catch(e => {
        console.log(e);
        return e;
      });
  };

  stopLoading = () => {
    this.setState({loading: false})
  }
  
  processCategories = (data) => {
    if (data['all_categories'] !== undefined) {
      this.allCategories = data['all_categories']
    }
    if (data['categories'] !== undefined && Array.isArray(data['categories'])) {
      const active = data['categories']
      const passive = this.allCategories.filter( e => !active.includes(e));
      this.setState({categoriesActive: active, categoriesPassive: passive})
    }
  }

  processCurrencies = (data) => {
    if (data['currencies'] === undefined) { return }
    //remember currencies
    const currencies = {}
    data['currencies'].forEach(e => {
      currencies[e['code']] = { name: e['name'], icon_url: e['icon_url'], checked: true }
    })
    this.setState({currencies: currencies})
  }

  createMarkers = (data) => {
    const locations = data['data'];
    const clusters = data['clusters'];
    this.newMarkerIds = [];

    locations.forEach(location => {
      const mId = location.id.toString();
      this.newMarkerIds.push(mId);
      if (this.markers[mId] !== undefined) { return }
      const marker = new window.google.maps.Marker({
        position: {lat: location['lat'], lng: location['lng']},
        icon: location.online_assisted ? this.markerOnlineImage : this.markerPhysicalImage,
        map: this.googleMap,
      });

      marker.addListener('spider_click', ()=>{
        this.refreshMarkerIcons();
        marker.oldIcon = marker.icon;
        marker.setIcon(this.markerSelectedImage);
        this.showLocationInfo(location);
        this.googleMap.panTo(marker.position);
        this.setState({hideSearch: true, filterPanelVisible: false});
      });
      this.markers[mId] = marker;
      this.oms.addMarker(marker);
    });

    clusters.forEach(cluster => {
      const cid = `${cluster.quantity}:${cluster.center_lat}:${cluster.center_lng}`;
      this.newMarkerIds.push(cid);
      if (this.markers[cid] !== undefined) { return }

      const marker = new window.google.maps.Marker({
        position: {lat: parseFloat(cluster.center_lat), lng: parseFloat(cluster.center_lng)},
        icon: this.clusterImage,
        label: {
          text: cluster.quantity.toString(),
          color: "white",
          fontWeight: "bold",
          fontSize: "22px",
          lineHeight: "60px"
        },
        map: this.googleMap,
      });
      marker.addListener('click', ()=>{
        const bounds = {east: cluster['e_lng'], north: cluster['n_lat'], south: cluster['s_lat'], west: cluster['w_lng'] };
        this.googleMap.fitBounds(bounds);

      });
      this.markers[cid] = marker;
    });

    this.clearOldMarkers();
  };

  clearOldMarkers = () => {
    for (let mId in this.markers) {
      if (!this.newMarkerIds.includes(mId)) {
        this.markers[mId].setMap(null);
        delete(this.markers[mId]);
      }
    }
  };

  onMapClick = (e) => {
    e.stop();
    this.panelClose();
    this.refreshMarkerIcons();
    this.setState({ hideSearch: false, panelContentData: null });
  };

  refreshMarkerIcons = () => {
    for (let prop in this.markers) {
      if (this.markers[prop].oldIcon !== undefined) {
        this.markers[prop].setIcon(this.markers[prop].oldIcon);
      }
    }
  }

  openSubmitForm = () => { this.setState({ submitFormOpened: true, panelContentData: null }) };

  closeSubmitForm = () => { this.setState({ submitFormOpened: false }) };

  filterToggle = () => {
    const panelContentData = this.state.panelContentData?.type === 'filter' ? null : { type: 'filter' };
    this.setState({ panelContentData });
  }

  filterClose = () => { this.setState({ filterPanelVisible: false }) }

  showLocationInfo = (location) => {
    this.setState({
      panelContentData: {
        type: 'location',
        location
      }
    });
  };

  filterShow = () => {
    this.setState({
      panelContentData: {
        type: 'filter'
      }
    });
  };

  panelClose = () => {
    this.setState({ panelContentData: null });
  };

  handleCategorySelect = (e) => { this.setState({ selectedCategory: e.target.innerText, loading: true }, ()=> this.onMapDragEnd()); }

  handleCategoryClear = () => { this.setState({ selectedCategory: null, loading: true }, ()=> this.onMapDragEnd()); }

  handleCurrencyClick = (e) => {
    const newCur = this.state.currencies
    newCur[e.target.dataset.code].checked = !newCur[e.target.dataset.code].checked
    this.setState({ currencies: newCur, loading: true }, ()=> this.onMapDragEnd())
  }               
           
  render() {

    const { panelContentData } = this.state;

    let panelContent;

    if (panelContentData?.type === 'location' && panelContentData?.location) {
      panelContent = <Info location={panelContentData.location} allCurrencies={this.state.currencies} />;
    } else if (panelContentData?.type === 'filter') {
    const { selectedCategory, categoriesActive, categoriesPassive, currencies } = this.state;

      panelContent = <Filter
        selectedCategory={selectedCategory}
        categoriesActive={categoriesActive}
        categoriesPassive={categoriesPassive}
        currencies={currencies}
        onCategorySelect={this.handleCategorySelect}
        onCategoryClear={this.handleCategoryClear}
        onCurrencyClick={this.handleCurrencyClick}
        onClose={this.panelClose} />
    }

    let sf = null
    
    if (this.state.mapInitialised && this.state.lat !== null) {
      sf = <SearchFormV2
        onSearch={this.search}
        lat={this.state.lat}
        lng={this.state.lng}
        hide={this.state.hideSearch}
        onFilterToggle={this.filterToggle}
        onFilterClose={this.panelClose}
        googleMap={this.googleMap}
        shrinkByPanel={panelContentData != null}
      />
    }    

    return (
      <>
        {sf}
        <Ad zoneId="HlFciVr-rzBByK3-iyA0B1o-9X9XQQE" size="300x100" hidden={panelContentData !== null} />
        <div id="map-container">
          <div id="map-canvas" ref={this.googleMapRef}/>

          <Panel>
            {panelContent}
          </Panel>
        </div>

        {this.state.loading ?
          <div className="gray-overlay flex-column">
            <img src='img/loader-dark.gif' alt="Spinner" className={Utils.isMobile() ? "top-aligned" : "center-aligned"}/>
          </div> :
          null
        }

        {this.state.submitFormOpened ?
          <SubmitForm onClose={this.closeSubmitForm} lat={this.state.lat} lng={this.state.lng}
                      currencies={this.state.currencies}/> :
          <button className="btn-add-listing" onClick={this.openSubmitForm}>Submit Listing</button>}
      </>
    )
  }
}

Map.propTypes = {
  initialZoom: PropTypes.number,
  initialLat: PropTypes.number,
  initialLng: PropTypes.number,
  isPreview: PropTypes.bool,
};

Map.contextType = UIContext;

export default Map;
