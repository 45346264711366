import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types';

import './SearchFormV2.scss'
// import { CURRENCY_ICONS } from '../constants/currencies';
import Utils from "./Utils";
import { UIContext } from '../context/ui';
import { addHiddenClass } from '../helpers/uiConfig';
import { goBack } from '../helpers/goBack';
// import { searchPlace } from '../api/searchPlace';
import { LOGO_SRC } from '../constants/assets';

const GooglePrediction = ({ matchedSubstrings, description }) => {
  let currentIndex = 0;
  const highlightedParts = [];

  matchedSubstrings.forEach(({ length, offset }) => {
    highlightedParts.push(
      <span key={currentIndex}>
        {description.substring(currentIndex, offset)}
      </span>
    );

    highlightedParts.push(
      <span key={`h-${offset}`} style={{ fontWeight: 'bold' }}>
        {description.substr(offset, length)}
      </span>
    );

    currentIndex = offset + length;
  });

  if (currentIndex < description.length) {
    highlightedParts.push(
      <span key={currentIndex}>
        {description.substring(currentIndex)}
      </span>
    );
  }

  return <span>{highlightedParts}</span>;
};

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isEmpty: true, 
      autocompleteResults: { categories: [], places: [] },
      googleAutocompleteResults: [],
      resultsHidden: false,
    };
    this.searchInputRef = createRef();
    // this.autocomplete = null;
    this.hide = false;
    this.googleAutocompleteToken = null;
    this.state.autocompleteService =
      new window.google.maps.places.AutocompleteService();
    this.state.placesService = new window.google.maps.places.PlacesService(
      props.googleMap
    );
  }

  componentDidMount() {
    this.mapClickListener = window.google.maps.event.addListener(this.props.googleMap, 'click', this.hideResults);
    this.mapDragStartListener = window.google.maps.event.addListener(this.props.googleMap, 'dragstart', this.hideResults);
    Utils.focusOnDesktop(this.searchInputRef.current);
  }

  componentWillUnmount() {
    this.mapClickListener?.remove();
    this.mapDragStartListener?.remove();
  }

  onSubmit = (event) => {
    if (event !== undefined) { event.preventDefault(); }
    this.googleAutocompleteToken = null;
    this.hideResults();
    const query = this.searchInputRef.current?.value;
    this.findPlaceByQuery(query);
  };

  handleGooglePlaceSuggestions = (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
      const results = predictions.map(p => ({ placeId: p.place_id, description: p.description }));
      this.setState({ googleAutocompleteResults: results });
    }
  };

  getGoogleSuggestions = (query) => {
    return new Promise((resolve, reject) => {
      const callback = (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          const results = predictions.map((p) => ({
            placeId: p.place_id,
            description: p.description,
            matchedSubstrings: p.matched_substrings
          }));
          resolve(results);
        }
      };
      
      if (this.googleAutocompleteToken === null) {
        this.googleAutocompleteToken =
          new window.google.maps.places.AutocompleteSessionToken();
      }
      
      this.state.autocompleteService.getPlacePredictions(
        {
          input: query,
          sessionToken: this.googleAutocompleteToken,
        },
        callback
      );
    
    });
  };

  searchFieldChanged = async (event, q) => {
    const query = this.searchInputRef.current.value;
    const newVal = query === '';
    
    if (newVal) {
      this.setState({ 
        autocompleteResults: { categories: [], places: [] },
        googleAutocompleteResults: [],
        isEmpty: true,    
      });
    } else {
      const googleSuggestions = await this.getGoogleSuggestions(query);
      // const bitcoincomSuggestions = await searchPlace(query);
      this.setState({
        // autocompleteResults: bitcoincomSuggestions,
        googleAutocompleteResults: googleSuggestions,
      });
    }
    
    if (this.state.isEmpty !== newVal) {
      this.setState({ isEmpty: newVal });
    }
  };

  resetForm = (event) => {
    event?.stopPropagation();
    this.searchInputRef.current.value = '';
    this.searchFieldChanged(event);
    Utils.focusOnDesktop(this.searchInputRef.current);
  };

  onSelectCategory = (category) => {};

  onSelectResult = (type, index) => {
    const query = this.searchInputRef.current;

    if (type === 'place') {
      const place = this.state.autocompleteResults.places[index];
      const latlng = {
        lat: +place.lat,
        lng: +place.lng
      };
      this.props.onSearch(query, latlng);
    } else if (type === 'category') {
      // TODO: add category selection callback
    }
    this.resetForm();
  };

  onSelectGoogleResult = (placeId) => {
    const sessionToken = this.googleAutocompleteToken;
    if (sessionToken === null) return;
    const callback = (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const query = this.searchInputRef.current;
        const location = place.geometry === undefined ? null: place.geometry.location;
        const latlng = {
          lat: location.lat(),
          lng: location.lng()
        };
        
        this.props.onSearch(query, latlng);
        this.resetForm();
      }
      this.googleAutocompleteToken = null;      
    };
    
    const request = {
      placeId,
      fields: ['geometry.location'],
      sessionToken
    }
    this.state.placesService.getDetails(request, callback);
  };

  findPlaceByQuery = (query) => {
    if (!query) {
      return;
    }

    const callback = (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && results?.length > 0) {
        const result = results[0];
        const location = result.geometry === undefined ? null: result.geometry.location;
        const latlng = {
          lat: location.lat(),
          lng: location.lng()
        };
        this.props.onSearch(query, latlng);
        this.resetForm();
      }
    };
    
    const request = {
      query,
      fields: ['geometry.location'],
    }
    this.state.placesService.findPlaceFromQuery(request, callback);
  };

  resetInput = () => {
    this.searchInputRef.current.value = '';
  };

  resetResults = () => {
    this.setState({
      autocompleteResults: { categories: [], places: []},
      googleAutocompleteResults: [],
    });
  };

  hideResults = () => {
    this.setState({ resultsHidden: true });
  };

  showResults = () => {
    this.setState({ resultsHidden: false });
  };

  onFilterToggle = () => {
    this.props.onFilterToggle();
  }
  
  handleFocus = () => {
    this.showResults();
    this.props.onFilterClose();
  }

    render() {
      if (
          !this.state.autocompleteService ||
          !this.state.placesService ||
          typeof this.state.placesService.getDetails !== 'function'
        ) {
        return null;
      }

      const { shrinkByPanel } = this.props;

      const { autocompleteResults: { categories, places }, googleAutocompleteResults, resultsHidden } = this.state;
      // const query = this.searchInputRef.current?.value;
      const { hasLogo, hasBackButton } = this.context;

      const hasResults = categories?.length > 0 || places?.length > 0 || googleAutocompleteResults?.length > 0;
      const shouldShowResults = hasResults && !resultsHidden;
      
      const placeholder = Utils.isMobile() ? 'Search for businesses' : 'Search for businesses that accept crypto payments';
      
      const shrinkClass = shrinkByPanel ? ' shrink-by-panel' : '';

      return (
        <header className={`${this.props.hide ? 'hidden' : ''}`}>
          <div className={addHiddenClass('logo', hasLogo)}>
            <a href="https://www.bitcoin.com/">
              <img
                src={LOGO_SRC} alt="Bitcoin.com logo"
                style={{width: shrinkByPanel ? 0 : undefined}} />
            </a>
          </div>
          <div className={`form${shrinkClass}`}>
            <button
              className={addHiddenClass('back-button', hasBackButton)}
              title="Go back"
              onClick={goBack}
            />
            <form
              className="search-form"
              onSubmit={this.onSubmit}
              onReset={this.resetForm}
            >
              <div className="search-wrapper">
                <div className="filter-wrapper">
                  <button
                    type="button"
                    className="icon filter"
                    onClick={this.onFilterToggle}
                  />
                </div>

                <input
                  type="text"
                  name="query"
                  autoComplete="off"
                  autoCorrect="off"
                  placeholder={placeholder}
                  ref={this.searchInputRef}
                  className="rounded"
                  onChange={this.searchFieldChanged}
                  onFocus={this.handleFocus} />
                {this.state.isEmpty ? null : (
                  <button type="reset" className="icon" />
                )}
              </div>
              <div className="submit-wrapper">
                <button className="icon" type="submit" />
              </div>
              {shouldShowResults && <div className="results-wrapper">
                {/* {categories?.length && (
                  <>
                  {categories.map(c => (
                    <div
                      key={c}
                      className="bcom-result-item"
                      onClick={() => this.onSelectCategory(c)}
                    >
                      {c}
                    </div>
                  ))}                  
                  <div className="separator" />
                  </>
                )} */}
                {/* {places?.length > 0 && (
                  <>
                    <p className="result-section-header">
                      {query} - <span>{'in Bitcoin.com Places'}</span>
                    </p>
                    {places.map((r, i) => (
                      <div
                        key={r.id}
                        className="bcom-result-item"
                        onClick={() => this.onSelectResult('place', i)}
                      >
                        {r.currency_ids.map((i) => (
                          <img
                            className="bcom-result-icon"
                            alt={i}
                            src={CURRENCY_ICONS[i]}
                          />
                        ))}
                        {r.name}
                      </div>
                    ))}
                    <div className="separator" />
                  </>
                )} */}
                {googleAutocompleteResults.map((r) => (
                  <div
                    key={r.placeId}
                    className="google-result-item "
                    onClick={() => this.onSelectGoogleResult(r.placeId)}
                    >
                      <span className="google-result-marker" />
                      <GooglePrediction  {...r} />
                  </div>
                ))}
              </div>}
            </form>
          </div>
        </header>
      );
    }
}

SearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onFilterToggle: PropTypes.func.isRequired,
  onFilterClose: PropTypes.func.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  hide: PropTypes.bool,
  googleMap: PropTypes.any.isRequired
};

SearchForm.contextType = UIContext;

export default SearchForm
