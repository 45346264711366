const Filter = ({
    selectedCategory,
    categoriesActive,
    categoriesPassive,
    onCategorySelect,
    onCategoryClear,
    currencies,
    onCurrencyClick,
    onClose,
}) => {
    let categoryFilter;

    if (selectedCategory) {
      categoryFilter = <ul className="categories">
        <li className="selected" onClick={onCategoryClear}>{selectedCategory}
            <span className="clear-btn"/>
        </li>
      </ul>
    } else {
      categoryFilter =
        <ul className="categories">
          {categoriesActive.map(category => {
            return <li key={category} onClick={onCategorySelect}>{category}</li>
          })}

          {categoriesPassive.map(category => {
            return <li key={category} className="inactive">{category}</li>
          })}
        </ul>
    }

    return (
        <>
            <h1>Currencies</h1>
            <button className="text-btn close-btn" onClick={onClose}>Done</button>
            <ul className="currencies">
              {Object.keys(currencies).map(code => {
                let obj = currencies[code]
                return <li key={code}>
                  <img src={obj.icon_url} alt={code} width="64" height="64"/>
                  <div>
                    <div className="cur-code">{code}</div>
                    <div className="cur-description">{obj.name}</div>
                  </div>
                  <div className="checkbox-slider-wrapper">
                    <input type="checkbox" data-code={code} checked={obj.checked} onChange={onCurrencyClick}/> <span className="checkbox-status"/>
                  </div>
                </li>
              })}
            </ul>
            <h1>Categories</h1>
            {categoryFilter}
        </>
    );
};

export default Filter;