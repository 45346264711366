import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types';
import { CATEGORIES } from './Categories'
import './SubmitForm.scss'
import classNames from "classnames";
import Utils from "./Utils";

const STAGE_SEARCH = 's';
const STAGE_EDIT = 'e';
const STAGE_RESULT = 'r';

class SubmitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitStage: STAGE_SEARCH,
      isEmpty: true,
      google_place_id: null,
      name: '',
      category: '',
      full_address: '',
      phone: '',
      url: '',
      contact_name: '',
      email: '',
      place_lat: null,
      place_lng: null,
      currency_codes: ['BCH'],

      err_name: false,
      err_full_address: false,
      err_contact_name: false,
      err_phone: false,
      err_email: false,
      err_currencies: false,
      err_url: false,
      err_category: false,

      request_error_message: '',
      request_in_progress: false,
      request_success: null,
    };
    this.stageMapping = {
      's': this.searchScreen,
      'e': this.editScreen,
      'r': this.resultScreen,
    };
    this.searchInputRef = createRef();
    this.autocomplete = null;
  }

  componentDidMount() {
    this.autocomplete = new window.google.maps.places.Autocomplete(this.searchInputRef.current, { types: ['establishment', 'geocode']});
    this.autocomplete.setFields(['geometry.location', 'place_id', 'name', 'formatted_address', 'international_phone_number', 'website']);
    window.google.maps.event.addListener(this.autocomplete, 'place_changed', this.onPlaceSelected );
    Utils.focusOnDesktop(this.searchInputRef.current);
    this.boundAutocomplete();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      this.boundAutocomplete();
    }
  }

  boundAutocomplete() {
    const latLng = new window.google.maps.LatLng(this.props.lat, this.props.lng);
    const bound = new window.google.maps.LatLngBounds(latLng);
    this.autocomplete.setBounds(bound);
  }

  searchFieldChanged = (event) => {
    const query = this.searchInputRef.current.value;
    const newVal = query === '';
    if (this.state.isEmpty !== newVal){
      this.setState({isEmpty: newVal})
    }
  };

  resetForm = (event) => {
    event.stopPropagation();
    this.searchInputRef.current.value = '';
    this.searchFieldChanged(event);
    Utils.focusOnDesktop(this.searchInputRef.current);
  };

  checkForInvalidUrl = (url) => {
    if (url !== '') {
      try {
        // eslint-disable-next-line no-unused-vars
        const site = new URL(url);
        return false;
      } catch (e) {
        this.setState({err_url: true});
        return true;
      }
    }
  }

  validateForm = () => {
    const res = {
      err_name: this.state.name === '',
      err_full_address: this.state.full_address === '',
      err_contact_name: this.state.contact_name === '',
      err_phone: this.state.phone === '',
      err_email: this.state.email === '',
      err_currencies: this.state.currency_codes.length === 0,
      err_url: false,
      err_category: this.state.category === '',
    };

    const { url } = this.state;
    res.err_url = this.checkForInvalidUrl(url);

    this.setState(res);
    let failed = false;
    for (let err of Object.values(res)) {
      failed = failed || err;
    }
    return failed;
  };

  onPlaceSelected = (event) => {
    if (event !== undefined) { event.preventDefault(); }
    const place = this.autocomplete.getPlace();
    let lat, lng;
    if (place.geometry !== undefined) {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
    }

    this.setState({
      name: place.name,
      full_address: place.formatted_address || '',
      phone: place.international_phone_number || '',
      url: place.website || '',
      place_lat: lat,
      place_lng: lng,
      google_place_id: place.place_id,
    });
  };

  onCheckboxChanged = (e) => {
    let arr = this.state.currency_codes
    if (e.target.checked) {
      arr.push(e.target.value)
    } else {
      const v = e.target.value 
      arr = arr.filter(e => e !== v)
    }
    this.setState({currency_codes: arr})
  }

  onSubmitSearchForm = (e) => {
    if (e !== undefined) { e.preventDefault(); }
    this.setState({
      submitStage: STAGE_EDIT,
      name: this.searchInputRef.current.value,
    })
  };

  onSubmitLocationForm = (e) => {
    if (e !== undefined) { e.preventDefault(); }
    const withErrors = this.validateForm();
    if (withErrors) { return }
    this.setState({request_in_progress: true});

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/locations`;

    const data = {
      google_place_id: this.state.google_place_id,
      name: this.state.name,
      category: this.state.category,
      full_address: this.state.full_address,
      phone: this.state.phone,
      web: this.state.url,
      contact_name: this.state.contact_name,
      email: this.state.email,
      lat: this.state.place_lat,
      lng: this.state.place_lng,
      currencies: this.state.currency_codes,
    };
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=utf-8");
    headers.append("X-Api-Key", process.env.REACT_APP_BACKEND_API_KEY);
    const options = {
      method: 'POST',
      mode: "cors",
      body: JSON.stringify(data),
      headers: headers,
    };

    fetch(apiUrl, options)
      .then(res => res.json())
      .then(result => {
          const isSuccess = result.error === undefined;
          let changes = {
            request_success: isSuccess,
            request_in_progress: false,
            request_error_message: '',
          };
          if (isSuccess) {
            changes['submitStage'] = STAGE_RESULT;
          } else {
            changes['request_error_message'] = result.error.message
          }
          this.setState(changes);
        },
        (error) => {
          this.setState({
            request_success: false,
            request_in_progress: false,
            request_error_message: error || '',
          });
        }
      );
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value, [`err_${name}`]: value === '' });
  };
  
  render() {
    const screen = this.stageMapping[this.state.submitStage];
    return (
      <div id="submit-form" className="listing-box">
        <div id="search-form" className="form">
          {screen()}
        </div>
      </div>
    )
  }

  searchScreen = () => {
    return (
      <>
        <form className="search-form" onReset={this.resetForm} onSubmit={this.onSubmitSearchForm}>
          <button type="button" className="listing-box-close" onClick={this.props.onClose}>Close</button>
          <div className="search-wrapper">
            <input type="text" name="query" className="rounded" autoComplete="off" autoCorrect="off" placeholder="Search for a business to submit" ref={this.searchInputRef} onChange={this.searchFieldChanged}/>
            {(this.state.isEmpty) ? null : <button type="reset" className="icon reset"/> }
          </div>
          <div className="submit-wrapper">
            <button className="icon" type="submit" />
          </div>
          {(this.state.isEmpty) ? null : <button className="btn-primary" onClick={this.onSubmitSearchForm}>Continue</button> }
        </form>
      </>
    );
  };

  editScreen = () => {
    return (
      <>
        <div className="listing-add">
          <h2>Submit a Business to Bitcoin.com Maps</h2>
          {this.state.request_error_message === '' ? null : <div className="error">{this.state.request_error_message}</div>}
          <form className="listing-form" onSubmit={this.onSubmitLocationForm}>
            <div className="listing-form-field">
              <label>Business Name <span className="required" title="required">*</span></label>
              <input type="text" name="name" className={classNames({error: this.state.err_name})} value={this.state.name} onChange={this.handleInputChange} />
            </div>
            <div className="listing-form-field">
              <label>Category <span className="required" title="required">*</span></label>
              <select
                name="category"
                className={classNames({'category-error': this.state.err_category},)}
                value={this.state.category}
                onChange={this.handleInputChange}
              >
                <option disabled value=''></option>
                {CATEGORIES.map((value, index) => {
                  return <option key={index} value={value}>{value}</option>
                })}
              </select>
            </div>
            <div className="listing-form-field full">
              <label>Address <span className="required" title="required">*</span></label>
              <input type="text" name="full_address" className={classNames({error: this.state.err_full_address})} value={this.state.full_address} onChange={this.handleInputChange} />
            </div>
            <div className="listing-form-field">
              <label>Phone <span className="required" title="required">*</span></label>
              <input type="text" name="phone" className={classNames({error: this.state.err_phone})} value={this.state.phone} onChange={this.handleInputChange} placeholder="+81 55 5555 5555" />
            </div>
            <div className="listing-form-field">
              <label>Website</label>
              <input type="text" name="url" className={classNames({error: this.state.err_url})} value={this.state.url} onChange={this.handleInputChange} />
              {this.state.err_url && <p id="website-error-message" >Please enter a valid website with http(s)</p>}
            </div>
            <div className="listing-form-field">
              <label>Contact Name <span className="required" title="required">*</span></label>
              <input type="text" name="contact_name" className={classNames({error: this.state.err_contact_name})} value={this.state.contact_name} onChange={this.handleInputChange} placeholder="Full Name" />
            </div>
            <div className="listing-form-field">
              <label>Email Address <span className="required" title="required">*</span></label>
              <input type="text" name="email" className={classNames({error: this.state.err_email})} value={this.state.email} onChange={this.handleInputChange} />
            </div>
            <div className={classNames("listing-form-field full", {error: this.state.err_currencies})}>
              <label>Accepted Cryptocurrencies <span className="required" title="required">*</span></label>

              {Object.keys(this.props.currencies).map((code) => {
                let c = this.props.currencies[code]
                return <label htmlFor={code} className="checkbox" key={code}>
                  <input type="checkbox" id={code} name="vehicle1" value={code} defaultChecked={this.state.currency_codes.includes(code)} onChange={this.onCheckboxChanged}/> {c.name}
                </label>
              })}
            </div>
            <div className="listing-form-actions">
              <button type="button" className="btn-secondary" onClick={this.props.onClose}>Cancel</button>
              <input type="submit" className={classNames('btn-primary', {spinner: this.state.request_in_progress})} value="Submit" onClick={this.onSubmitLocation}/>
            </div>
          </form>

          <div className="listing-form-options">
            Questions or comments? <a href="mailto:support-maps@bitcoin.com?subject=Question or comment&body=\n">Please contact support</a>
          </div>
        </div>
      </>
    );
  };

  resultScreen = () => {
    return (
      <>
        <div className="listing-add">
          <h2>Listing submitted!</h2>
          <p>
            It can take up to 48h for approval. Please submit your business once, multiple
            submissions may cause delays. Only brick and mortar businesses will be added to
            the map.
          </p>
          <button className="btn-primary" onClick={this.props.onClose}>Got it!</button>
        </div>
      </>
    );
  }
}

SubmitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  currencies: PropTypes.object.isRequired,
};

export default SubmitForm
