import { defaultUI } from '../constants/defaultUi';

const parseUIConfig = (urlParams) => {
  const ui = { ...defaultUI };
  if (typeof urlParams === 'undefined' || !urlParams.has('ui')) return ui;
  const uiParams = urlParams.get('ui').split(',');

  uiParams.forEach((p) => {
    const isDisabled = p.startsWith('no-');
    const element = p.replace('no-', '');
    switch (element) {
      case 'logo':
        ui.hasLogo = !isDisabled;
        break;
      case 'search':
        ui.hasSearch = !isDisabled;
        break;
      case 'back':
        ui.hasBackButton = !isDisabled;
        break;
      case 'zoom':
        ui.hasZoom = !isDisabled;
        break;
      case 'fullscreen':
        ui.hasFullScreenButton = !isDisabled;
        break;
      default:
        break;
    }
  });

  return ui;
};

const addHiddenClass = (baseClass, isEnabled) => {
  if (typeof isEnabled === 'undefined') return baseClass;
  const hiddenClass = isEnabled === true ? '' : ' off';
  return baseClass + hiddenClass;
};

export { parseUIConfig, addHiddenClass };
