export const CATEGORIES = [
  'Adult Entertainment',
  'Airlines & Charters',
  'Arts',
  'ATM & Vending Machine',
  'Automotive Shops & Services',
  'Bar',
  'Beauty, Barber & Spas',
  'Bike, Scooter and Motorcycle Shop',
  'Books and Magazines',
  'Brewery',
  'Cafe',
  'Cannabis Dispensaries',
  'Catering Services',
  'Comic Books',
  'Construction',
  'Desserts and Bakery',
  'Education',
  'Electronics',
  'Entertainment & Media',
  'Event Planning',
  'Event Venue',
  'Fast Food',
  'Financial Services',
  'Flower and Gifts',
  'Food Truck and Stands',
  'Gas Station',
  'Grocery & Farmers Market',
  'Health & Medical',
  'Home Services',
  'Hotels & Hostels',
  'Ice Cream & Frozen Yogurt',
  'Jewelry & Watches',
  'Kids',
  'Liquor Store',
  'Mini-marts & Convenience Stores',
  'Museum',
  'Music and Video',
  'Nightlife',
  'Pets & Services',
  'Pizza and Sandwiches',
  'Professional Services',
  'Public Services',
  'Real estate',
  'Religion and Spiritual',
  'Rental Services',
  'Restaurants',
  'Shopping',
  'Souvenir Store',
  'Sports & Outdoor',
  'Taxi Services',
  'Toys and Hobbies',
  'Transportation',
  'Travel & Agency',
];
