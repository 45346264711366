const API_URL
 = 'https://neko-web.api.wallet.bitcoin.com/api/v1/location/info';

export const getUserCountryCode = async () => {
    const response = await fetch(API_URL);

    if (!response.ok) {
        return 'WORLD';
    }

    const data = await response.json();

    return data?.countryCode || 'WORLD';
};