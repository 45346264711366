import { REGIONAL_VIEWS, COUNTRY_CODE_MAP } from "../constants/regionalViews";
import { getUserCountryCode } from "./getUserCountryCode";

export const getInitialView = async () => {
    const countryCode = await getUserCountryCode();

    const platform = window.document.body.offsetWidth > 700 ? 'desktop' : 'mobile';

    const platformViews = REGIONAL_VIEWS[platform];
    
    return platformViews[countryCode] || platformViews[COUNTRY_CODE_MAP[countryCode] || 'WORLD'];
};