import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <Helmet title="Bitcoin.com Maps | Search for businesses that accept crypto">
      <meta
        name="description"
        content="Use the interactive map to find merchants in your area that accept payment in BTC, BCH, and ETH. Add your business to get discovered by crypto users."
      />
      <link rel="canonical" href="https://map.bitcoin.com/" />
    </Helmet>
  );
};

export default SEO;
