import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';

const Ad = ({ className, zoneId, size, hidden, ...rest }) => {
    const placementRef = useRef(null);

    useEffect(() => {
        if (typeof window !== 'undefined' && zoneId && placementRef.current) {
            const sizeArr = size?.split('x').map(s => +s) || null;
            const GrowJs = window.GrowJs || {};
            GrowJs.ads = GrowJs.ads || [];
            GrowJs.ads.push({
                node: placementRef?.current,
                handler(node) {
                const banner = GrowJs.createBanner(node, zoneId, sizeArr, null, []);
                GrowJs.showBanner(banner.index);
                },
            });
    }
    }, [zoneId, size]);

    const hiddenClass = hidden ? ' hidden' : '';

    const mergedContainerClasses =
        `ad-wrapper${hiddenClass}${typeof className === 'string' ? ` ${className}` : ''}`;

    return (
        <div className={mergedContainerClasses} {...rest}>
            <ins ref={placementRef}>
                <ins className="growjs-placement" />
            </ins>
        </div>
    );
};

Ad.propTypes = {
    className: PropTypes.string,
    zoneId: PropTypes.string.isRequired,
    size: PropTypes.string,
};

export default Ad;