const makeUrl = (web) => {
    if (!web) return '';
    let url = web.replace(' ', '').toLowerCase();
    if (!url.includes('http')) {
        url = `http://${url}`;
    }
    try {
        return new URL(url).toString();        
    } catch(e) {
        return '';
    }
};

export { makeUrl };