import React, {Component} from 'react';
import './App.css';
import Map from './elements/Map';
import SEO from './elements/SEO';
import { UIContext } from './context/ui';
import { parseUIConfig } from './helpers/uiConfig';

class App extends Component {
  render() {
    const params = new URLSearchParams(window.location.search);
    const lat = parseFloat(params.get('lat'));
    const lng = parseFloat(params.get('lng'));
    const zoom = parseInt(params.get('zoom'));
    const uiConfig = parseUIConfig(params);
    const isPreview = params.get('preview') === 'true';
    return (
      <div className="app">
        <SEO />
        <UIContext.Provider value={uiConfig}>
          <Map
          initialLat={lat}
          initialLng={lng}
          initialZoom={zoom}
          isPreview={isPreview}
        />
        </UIContext.Provider>
      </div>
    );
  }
}

export default App;
